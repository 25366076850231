import React from 'react';
import ReactDOM from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './index.css';
import App from './App';
import store from './store';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { addTask } from './store/Task/index'; // Correct action for adding tasks
import tasksData from './data.json'; // Your JSON data

// Dispatch actions to set tasks in the store
tasksData.tasks.forEach(task => store.dispatch(addTask(task)));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// store.subscribe(() => console.log("ss",store.getState()));