import { createSlice } from '@reduxjs/toolkit';

const loadTagsFromLocalStorage = () => {
    const storedTags = localStorage.getItem('tags');
    return storedTags ? JSON.parse(storedTags) : [];
};

const tagSlice = createSlice({
    name: 'tags',
    initialState: {
        tags: loadTagsFromLocalStorage(), // Load initial state from localStorage
    },
    reducers: {
        setTags: (state, action) => {
            state.tags = action.payload;
            localStorage.setItem('tags', JSON.stringify(state.tags)); // Save to localStorage
        },
        addTag: (state, action) => {
            state.tags = [action.payload, ...state.tags]; 
            localStorage.setItem('tags', JSON.stringify(state.tags)); // Save to localStorage
        },
        updateTag: (state, action) => {
            const { index, value } = action.payload;
            if (value) {
                state.tags[index] = value;
            } else {
                state.tags = state.tags.filter((_, i) => i !== index);
            }
            localStorage.setItem('tags', JSON.stringify(state.tags)); // Save to localStorage
        },
    },
});

export const { setTags, addTag, updateTag } = tagSlice.actions;
export default tagSlice.reducer;
