import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./Style.css";
import DragIcon from "../../../assets/images/svg/Drag_icon.svg";
import InputBox from "../../Atom/Input/InputField";
import RangeBar from "../../Atom/RangBar/rangBar";
import Tags from "../../Atom/Tags/tagsV2";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import Comments from "../../../assets/images/svg/Comments.svg";
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import Dropdown from "../../../assets/images/svg/DropDown_arrow.svg";
import TaskRow from "./TaskRow";
import Data from "../../../data.json"
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { addTagHelper, removeTagHelper } from "../../Atom/Tags/helper";
import { addTagToSection, updateSectionTags } from "../../../store/Section/index";
import useCustomComposable from "../../utils/composables";
const ItemType = "SECTION";

const DraggableSection = ({
    id,
    index,
    moveSection,
    item,
    inputValue,
    handleInputChange,
    taskValue,
    handleTaskChange,
    addRow,
    handleTaskRowChange,
    tags: sectionTags
}) => {
    console.log("tag",item);
    const [tags, setTags] = useState(item.tags);
    const [isSectionVisible, setIsSectionVisible] = useState(true);

    const dispatch = useDispatch();
    const {makeUniqueId} = useCustomComposable();

    const allTags = useSelector((state) => state.tags.tags);
    const tasks = useSelector((state) => state.tasks.tasks);
    const [sectionTagsData, setSectionTagsData] = useState([]);
    const [rows, setRows] = useState([]);
    // Get tasks for a specific section
    const getTasksForSection = () => {
        const tmp = tasks.filter((task) => task.sectionId === id)
        
        setRows(tmp);
    };
    useEffect(() => {
        getTasksForSection();
    }, [tasks])

    const handleTagRemove = (index) => {
        setTags((prevTags) => prevTags.filter((_, i) => i !== index));
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addRow(taskValue); // Call addRow function to add a new row
        }
    };

    const [, ref] = useDrag({
        type: ItemType,
        item: { id, index },
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveSection(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const toggleVisibility = () => {
        setIsSectionVisible(!isSectionVisible);
    };

    // // HANDLE TAGS
    useEffect(() => {
        console.log("sectionTags:", sectionTags);
        const tmpSectionTags = sectionTags?.map((x) => allTags.find(y => y.id === x))?.filter((x) => x);

    
        setSectionTagsData(tmpSectionTags || []);
    }, [sectionTags, allTags]);
    


    function handleAddTag(tagName) {
        if (!tagName) return;
    
        const allTaskTags = [...sectionTagsData];

        const tagValue = allTaskTags.find(x => x.value === tagName)

        if (tagValue) {
            toast.error("Please enter a unique tag.");
            return;
        }

        const existingTag = allTags.find(tag => tag.value === tagName);
        
        // Add the tag
        if(existingTag) {
            dispatch(addTagToSection({sectionId: item.id, tag: existingTag.id}));
            toast.success(`Tag "${existingTag.value}" added successfully!`);    
        } else {
            const uniqueId = makeUniqueId(); // Implement this function to generate a unique ID
            addTagHelper({id: uniqueId, value: tagName}, dispatch); // Ensure this helper function works correctly
            dispatch(addTagToSection({sectionId: item.id, tag: uniqueId}));
            toast.success(`New tag "${tagName}" created and added!`);
        }
    }
    const handleRemoveTag = (tag) => {
        const removeTagId = sectionTags.find((x) => x === tag.id);
        dispatch(updateSectionTags(item.id, removeTagId ,'remove'))
    
        toast.success(`Tag "${tag.id}" removed successfully!`);
    };

    return (
        <div className="main-wrapper">
            <div ref={(node) => ref(drop(node))} className="section-header">
                <div className="section-left">
                    <img
                        className="section-drag-icon"
                        src={DragIcon}
                        alt="DragIcon"
                    />
                    <img
                        className={`section-dropdown-icon ${
                            !isSectionVisible ? "rotate" : ""
                        }`}
                        src={Dropdown}
                        alt="Dropdown"
                        onClick={toggleVisibility}
                        style={{ cursor: "pointer" }}
                    />
                    <InputBox value={inputValue} onChange={handleInputChange} />
                    <RangeBar initialValue={0} />
                </div>
            </div>
            <div className={`section-tags ${!isSectionVisible ? "hidden" : ""}`}>
                All <Tags tags={sectionTagsData} tagOptions={allTags} handleAddTag={handleAddTag} handleRemoveTag={handleRemoveTag}/>
            </div>
            <div className={`section-table ${!isSectionVisible ? "hidden" : ""}`}>
                <table className="tasks-table">
                    <thead>
                        <tr className="tasks-table-header">
                            <th></th>
                            <th>Task</th>
                            <th>Status</th>
                            <th>Due Date</th>
                            <th>Organisation</th>
                            <th>Assignee</th>
                            <th>Comments</th>
                            <th>Tags</th>
                            <th>section</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="tasks-table-header">
                            <td>
                                <img
                                    className="section-drag-icon"
                                    src={DragIcon}
                                    alt="DragIcon"
                                />
                            </td>
                            <td>
                                <InputBox
                                    value={taskValue}
                                    placeholder={"write a task name"}
                                    onChange={handleTaskChange}
                                    onKeyPress={handleKeyPress}
                                />
                            </td>
                            <td>
                                <CircularProgressBar percentage={0} />
                            </td>
                            <td>
                                <MyDatePicker />
                            </td>
                            <td>
                                <img src={OrganisationIcon} alt="Organisation" />
                            </td>
                            <td>
                                <img src={AssigneeIcon} alt="AssigneeIcon" />
                            </td>
                            <td>
                                <img src={Comments} alt="Comments" />
                            </td>
                            <td>
                                {/* <Tags 
                                    tags={[]}
                                    handleTagRemove={handleTagRemove}
                                    disabled={true}
                                /> */}
                            </td>
                            <td>
                                {/* <Tags 
                                    tags={[]}
                                    handleTagRemove={handleTagRemove}
                                    disabled={true}
                                /> */}
                            </td>
                        </tr>
                        {rows?.map((row, rowIndex) => (
                            <TaskRow
                                key={rowIndex}
                                row={row}
                                sectionTags={sectionTags}
                                handleTaskChange={(e) => handleTaskRowChange(e, row.id)}
                                handleStatusChange={(status) => handleTaskRowChange(status, row.id)}
                                handleDueDateChange={(date) => handleTaskRowChange(date, row.id)}
                                handleOrganisationChange={(organisation) => handleTaskRowChange(organisation, row.id)}
                                handleAssigneeChange={(assignee) => handleTaskRowChange(assignee, row.id)}
                                handleCommentsChange={(comments) => handleTaskRowChange(comments, row.id)}
                                handleTagsChange={(tagIndex) => handleTaskRowChange(tagIndex, row.id)}
                                index={row.id}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DraggableSection;
