import React, { useState, useEffect, useRef } from 'react';
import ClosedIcon from "../../../assets/images/svg/Close_Icon.svg";
import { filterTags } from './helper';
import Dropdown from '../CustomDropDown/CustomDropDown';
import { useCustomComposable } from "../../utils/composables/index";
import './Style.css';
import InputBox from '../Input/InputField';

function debounce(func, timeout = 300) {
    let timer = null;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(null, args); }, timeout);
    };
};

const Tags = ({ tags = [], tagOptions = [], disabled = false, handleAddTag, handleRemoveTag }) => {

    const [newTag, setNewTag] = useState('');
    const [activeTags, setActiveTags] = useState([]);
    const [filteredTags, setFilteredTags] = useState([]);

    // REFS
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        setActiveTags(tags)
    },[tags]);


    // FILTER TAGS
    const debouncedFilterTags = (inputValue) => {
        const results = filterTags(inputValue, tagOptions);
        setFilteredTags(results);
    };

    useEffect(() => {
        debouncedFilterTags(newTag);
    }, [newTag]);

    useEffect(debounce(() => {
        if (newTag && filteredTags.length > 0 && dropdownRef.current) {
            dropdownRef.current?.openDropDown();
        } else {
            dropdownRef.current?.closeDropDown();
        }
    }, 700), [filteredTags]);

    return (
        <div className={`tags ${disabled ? 'tags--disabled' : ''}`}>
            {activeTags?.map((tag, index) => (
                <div title={tag.value} key={index} className={`tag ${disabled ? 'tag--disabled' : ''}`}>
                   <span>
                     {tag.value}
                   </span>
                    {!disabled && (
                        <button
                            className="tag__remove-button"
                            onClick={() => handleRemoveTag(tag)}
                            aria-label="Remove tag"
                        >
                            <img src={ClosedIcon} alt='Close icon' />
                        </button>
                    )}
                </div>
            ))}
            {
                disabled ? 
                    <></>
                :
                <div className="dropdown-suggestions">
                    <div className={'tag'} ref={inputRef}>
                        <InputBox
                            type="text"
                            value={newTag}
                            onClick={(e) => e.preventDefault()}
                            onChange={(e) => setNewTag(e.target.value)}
                            placeholder="Add Tag"
                            disabled={disabled}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleAddTag(newTag);
                                    setNewTag("");
                                }
                            }}
                        />
                        {newTag && filteredTags.length > 0 && (
                            <Dropdown targetRef={inputRef} ref={dropdownRef}>
                                <div className="dropdown-suggestions">
                                    {filteredTags?.map((tag, index) => (
                                        <div
                                            className='dropdown-item'
                                            key={index}
                                            onClick={() => {
                                                handleAddTag(tag.value);
                                                setNewTag("");
                                            }}
                                        >
                                            {tag.value}
                                        </div>
                                    ))}
                                </div>
                            </Dropdown>
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default Tags;

