import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { updateTaskTags } from '../Task';

const sectionSlice = createSlice({
    name: 'sections',
    initialState: {
        sections: JSON.parse(localStorage.getItem('Sections')) || [], // Fetch sections from localStorage
    },
    reducers: {
        setSection: (state, action) => {
            state.sections = action.payload;
            localStorage.setItem('Sections', JSON.stringify(state.sections)); // Save to localStorage
        },
        addSection: (state, action) => {
            state.sections.push(action.payload); // Add new section
            localStorage.setItem('Sections', JSON.stringify(state.sections)); // Save to localStorage
        },
        updateSection: (state, action) => {
            const { id, field, value } = action.payload;
            state.sections = state.sections.map(section =>
                section.id === id ? { ...section, [field]: value } : section
            );
            localStorage.setItem('Sections', JSON.stringify(state.sections)); 
        },
        addTag: (state, action) => {
            const { sectionId, tag } = action.payload;
        
            if (!state.sections || state.sections.length === 0) {
                console.error("Sections not loaded in state. Please verify the initial state or load sections.");
                return;
            }
        
            // Check if the specific section exists in the current state
            const sectionExists = state.sections.some(section => section.id === sectionId);
            if (!sectionExists) {
                console.error("Section not found:", sectionId, "in sections:", state.sections);
                return;
            }
        
            // Update the section's tags in an immutable way
            const updatedSections = state.sections.map(section => {
                if (section.id === sectionId) {
                    const updatedTags = section.tags ? [...section.tags, tag] : [tag]; 
                    return {
                        ...section,
                        tags: updatedTags,
                    };
                }
                return section;
            });
        
            // Update the state with new sections array
            state.sections = updatedSections;
        
            // Save updated sections to localStorage
            localStorage.setItem('Sections', JSON.stringify(updatedSections));
        },
        
        updateSectionTags: (state, action) => {
            const { sectionId, tags } = action.payload;
            const sectionIndex = state.sections.findIndex(section => section.id === sectionId);
        
            if (sectionIndex !== -1) {
                // Update only the tags property without affecting other section properties
                state.sections[sectionIndex] = { 
                    ...state.sections[sectionIndex], 
                    tags: tags || [] 
                };
                localStorage.setItem("Sections", JSON.stringify(state.sections));
            }
        },
    },
});


export const updateSectionTags = (sectionId, tag, actionType) => (dispatch, getState) => {
    const state = getState(); 
    const tasks = state.tasks.tasks; 

    const relatedTasks = tasks.filter(task => task.sectionId === sectionId);

    const tasksWithTagToRemove = relatedTasks.filter(task => task.tags && task.tags.includes(tag));

    if (actionType === 'add') {
        dispatch(sectionSlice.actions.addTagToSection({ sectionId, tag }));
    } else if (actionType === 'remove') {
        const section = state.sections.sections.find(section => section.id === sectionId);
        if (section) {
            const updatedTags = section.tags.filter(t => t !== tag);

            dispatch(sectionSlice.actions.updateSectionTags({ sectionId, tags: updatedTags }));

            tasksWithTagToRemove.forEach(task => {
                const updatedTaskTags = task.tags.filter(t => t !== tag);
                dispatch(updateTaskTags({ taskId: task.id, tags: updatedTaskTags }));
            });
        } else {
            console.error("Section not found with ID:", sectionId);
        }
    }
};

export const addTagToSection = (sectionId, tag) => (dispatch, getState) => {
    const state = getState(); 
    const tasks = state.tasks.tasks;

    dispatch(sectionSlice.actions.addTag({sectionId: sectionId.sectionId, tag:sectionId.tag }));

    const relatedTasks = tasks.filter(task => task.sectionId === sectionId.sectionId);

    relatedTasks.forEach(task => {
        if (!task.tags || !task.tags.includes(sectionId.tag)) {
            const updatedTaskTags = task.tags ? [...task.tags, sectionId.tag] : [sectionId.tag];
            dispatch(updateTaskTags({ taskId: task.id, tags: updatedTaskTags }));
        }
    });
};



// Export actions and reducer
export const { setSection, addSection, updateSection } = sectionSlice.actions;
export default sectionSlice.reducer;
