// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import tasksReducer from './Task/index'; // Import tasks reducer
import sectionsReducer from './Section/index'; // Import sections reducer
import tagsReducer from './Tags/index'

const store = configureStore({
    reducer: {
        tasks: tasksReducer, // Add tasks reducer to the store
        sections: sectionsReducer, // Add sections reducer to the store
        tags: tagsReducer
    },
});

export default store;

