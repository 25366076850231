import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './components/context/Mainlayout';
import Sidebar from './components/context/Sidebar/sideBarLayout';
import Tasks from './components/Pages/Tasks';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <MainLayout sidebar={<Sidebar />}>
        <Routes>
          <Route path="/" element={<Tasks />} />
        </Routes>
      </MainLayout>
      <ToastContainer />
    </Router>
  );
}

export default App;
